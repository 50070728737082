import React, { useState, useRef } from 'react';
import { useStorePersist } from 'store/context';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './styles.scss';

import { Tooltip } from 'components/atoms/TooltipDialog/Tooltip';
import TooltipFade from 'components/atoms/TooltipDialog/TooltipFade';
import './styles.scss';

const DetailBody = ({
  id,
  title,
  content,
  index,
  images,
  videos,
  certifications,
  currentMaterial,
  onClick
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [{ tooltipCompleted, tooltipIndex }] = useStorePersist();
  const carouselRef = useRef(null);

  const handleRestart = pageIndex => {
    if (images.length > 1) {
      const totalItems = images.length - 1;
      if (pageIndex === totalItems) {
        carouselRef.current.goTo(0);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div id={id} onClick={onClick} className="route-details">
      {!tooltipCompleted && <TooltipFade />}

      {/* <span className="card-pill card-pill-material">{currentMaterial}</span> */}
      <h5 className={'card-title-' + index}>{title}</h5>

      {videos &&
        videos.length > 0 &&
        videos.map(
          vd =>
            vd.video !== null && (
              <>
                {vd.is_streaming_video ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={vd.video}
                    title="Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="card-iframe-video"
                  ></iframe>
                ) : (
                  <div className="video-container">
                    <video autoPlay playsInline loop muted>
                      <source src={vd.video} />
                    </video>
                  </div>
                )}
              </>
            )
        )}

      <p className="route-body-detail-mobile body">{content}</p>

      {images && images?.length > 0 ? (
        <Carousel
          ref={carouselRef}
          itemsToShow={1}
          initialActiveIndex={0}
          showArrows={false}
          pagination={false}
          enableAutoPlay={true}
          autoPlaySpeed={2500}
          transitionMs={2500}
          enableTilt={true}
          onNextEnd={handleRestart}
        >
          {images ? (
            images?.map(img => (
              <div className="detail-image-container">
                <img
                  src={img.image}
                  alt={title}
                  className={'detail-image'}
                />
              </div>
            ))
          ) : (
            <></>
          )}
        </Carousel>
      ) : (
        <></>
      )}

      {certifications?.length > 0 && certifications[0].files.length > 0 && (
        <>
          {!tooltipCompleted && tooltipIndex === 3 && <Tooltip />}
          <span
            id={'method' + id}
            className="detail-certification-link"
            onClick={() => setShowModal(true)}
          >
            {certifications?.length > 0 && certifications[0].name}
          </span>
        </>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="certification-modal-container"
        centered
      >
        <Modal.Body>
          <CloseButton variant="white" onClick={() => setShowModal(false)} />
          <a
            download
            href={`${certifications &&
              certifications.length > 0 &&
              certifications[0].files[0].file}`}
            className="detail-certification-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            {certifications?.length > 0 && certifications[0].name}
          </a>
          <div className="certificatiion-mobile-pdf-container">
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${'3.4.120'}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={`${certifications &&
                  certifications.length > 0 &&
                  certifications[0].files[0].file}`}
              />
            </Worker>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DetailBody;
