import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Environment from 'environment';
import { Context, ContextPersist } from './store/context';
import { CSSTransition } from 'react-transition-group';
import HomeView from './views/Home/HomeView';
import MapView from './views/Map/MapView';
import HomeLensView from './views/HomeLens/HomeLens';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize(Environment.analytics);
  // Fix resizeObserver Error
  useEffect(() => {
    window.addEventListener('error', e => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message ===
          'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <ContextPersist>
      <Context>
        <main>
          <Switch>
            <React.Fragment>
              <div>
                <Route exact path="/">
                  <div id="home-page">
                    <HomeView />
                  </div>
                </Route>
                <Route exact path="/map">
                  <div id="map-page">
                    <MapView />
                  </div>
                </Route>
                <Route exact path="/search">
                  <div id="search-page">
                    <HomeLensView />
                  </div>
                </Route>
              </div>
            </React.Fragment>
          </Switch>
        </main>
      </Context>
    </ContextPersist>
  );
}

export default App;
