import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import common_es from './translations/es/common.json';
import common_en from './translations/en/common.json';

const resources = {
  en: {
    translation: common_en
  },
  es: {
    translation: common_es
  }
};

i18n
  .use(LanguageDetector) // https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('react-store')
      ? JSON.parse(localStorage.getItem('react-store')).locale
      : 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      useSuspense: true
    }
  });

export default i18n;
