import React, { useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import MainLayout from 'components/organisms/MainLayout';
//import { ReactComponent as JoinPlanetDkt } from 'assets/images/join_planet_dkt.svg';
//import { ReactComponent as JoinPlanetMobile } from 'assets/images/join_planet_mobile.svg';
import ActionButton from 'components/atoms/ActionButton';
import FormSelect from '../../atoms/FormSelect';
import { isMobile } from 'helpers/Mobile';
import video from '../../../assets/videos/home_videobg_dkt.mp4';
import './styles.scss';

const HomeLensTemplate = React.memo(
  ({
    lens,
    sku = '',
    dinamicCountrySelect,
    dinamicStateSelect,
    dinamicCitySelect,
    setPosId,
    setCountry,
    setState,
    setCity,
    handleGetStates,
    handleGetCities,
    handleClickIngresar,
    error,
  }) => {
    const { t } = useTranslation();
    const [activeSelect, setActiveSelect] = useState(0);

    const handleCountryCity = e => {
      setCountry(e.target.value, e.target[e.target.selectedIndex].text);

      const selectedCountryObj = dinamicCountrySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      setCity(selectedCountryObj.city);
      setPosId(selectedCountryObj.posId);
      setActiveSelect(prevState => prevState + 1);
    };

    const handlePopulateStates = e => {
      const selectedCountryObj = dinamicCountrySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      handleGetStates(selectedCountryObj.id_country);
      setCountry(selectedCountryObj.id_country, selectedCountryObj.name);
      setActiveSelect(prevState => prevState + 1);
    };

    const handlePopulateCities = e => {
      const selectedStateObj = dinamicStateSelect.options.find(
        item => item.value.toString() === e.target.value
      );
      handleGetCities(selectedStateObj.id_state);
      setState(selectedStateObj.id_state, selectedStateObj.name);
      setActiveSelect(prevState => prevState + 1);
    };

    const handleCity = e => {
      const selectedCityObj = dinamicCitySelect.options.find(
        item => item.value.toString() === e.target.value
      );
      setCity(selectedCityObj.id_city, selectedCityObj.name);
      setActiveSelect(prevState => prevState + 1);
    };

    const disabledButton =
      (lens.posType === 'T2' && activeSelect !== 1) ||
      (lens.posType === 'T3' && activeSelect !== 3);

    return (
      <MainLayout showMenu={false} isSearch={true}>
        <div className="lens-container">
          {!isMobile && (
            <div className="video-background-container-dkt">
              <video autoPlay loop muted playsInline>
                <source src={video} />
              </video>
            </div>
          )}
          <div className="lens-content">
            <div className="lens-data">
              {/* {isMobile ? (
              <JoinPlanetMobile className="joinPlanetMobile" />
            ) : (
              <JoinPlanetDkt className="joinPlanetDkt" />
            )} */}
              {lens && (
                <img
                  className={'lens-image ' + sku}
                  src={lens.url}
                  alt={lens.name}
                />
              )}

              {lens.posType === 'T2' ? (
                <FormSelect
                  options={dinamicCountrySelect.options}
                  label={t('search.countryLabel')}
                  ariaLabel={'aria-label-countries'}
                  onChange={e => handleCountryCity(e)}
                  placeHolder={t('search.countryPlaceHolder')}
                />
              ) : (
                <div className="selects-container">
                  <FormSelect
                    options={dinamicCountrySelect.options}
                    label={t('search.countryLabel')}
                    ariaLabel={'aria-label-countries'}
                    onChange={e => handlePopulateStates(e)}
                    placeHolder={t('search.countryPlaceHolder')}
                  />

                  {dinamicStateSelect.options.length > 0 && (
                    <FormSelect
                      options={dinamicStateSelect.options}
                      ariaLabel={'aria-label-countries'}
                      onChange={e => handlePopulateCities(e)}
                      placeHolder={t('search.statePlaceHolder')}
                    />
                  )}

                  {dinamicCitySelect.options.length > 0 && (
                    <FormSelect
                      options={dinamicCitySelect.options}
                      ariaLabel={'aria-label-countries'}
                      onChange={e => handleCity(e)}
                      placeHolder={t('search.cityPlaceHolder')}
                    />
                  )}
                </div>
              )}
              <p
                className="select-all-fields"
                style={{ display: disabledButton ? 'block' : 'none' }}
              >
                {t('search.selectAllFields')}
              </p>
            </div>
            <div className="lens-data last-element">
              {error && (
                <div className={'input-error '}>
                  <p>{error}</p>
                </div>
              )}
              <ActionButton
                className="action-button-homelens"
                buttonText={t('search.submit')}
                handleClick={handleClickIngresar}
                disabled={disabledButton}
              />
              <div className="back-button">
                <a href="/">
                  <div></div>
                  <p>{t('search.back')}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
);

export default withTranslation()(HomeLensTemplate);
