import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Environment from 'environment';
import HomeLensTemplate from '../../components/templates/HomeLens';
import { useStorePersist } from 'store/context';
import { useTranslation } from 'react-i18next';
import { PointsOfSaleService } from 'services/PointsOfSaleService';

ReactGA.initialize(Environment.analytics);

ReactGA.event({
  category: 'Product_Code_Activity',
  action: 'entered_screen_2',
});

const HomeLens = props => {
  const history = useHistory();
  const [pointsOfSale, setPointsOfSale] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [citiesList, setCitiesList] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [{ lens, countryStr, stateStr, cityStr }, dispatchPersist] = useStorePersist();
  const { i18n } = useTranslation();
  const language = i18n.language !== 'en' && i18n.language;
  const [error, setError] = useState();

  useEffect(() => {
    localStorage.removeItem('routesData-en');
    localStorage.removeItem('routesData-es');
    localStorage.removeItem('routes-en');
    localStorage.removeItem('routes-es');


    if (lens.posType === 'T2') {
      PointsOfSaleService.list(lens.batch)
        .then(data => {
          setPointsOfSale(data);
        })
        .catch(err => console.log(err));
    }

    if (lens.posType === 'T3') {
      PointsOfSaleService.country()
        .then(data => {
          setCountriesList(data);
          dispatchPersist({
            type: 'setPosId',
            payload: null
          });
        })
        .catch(err => console.log(err));
    }
    dispatchPersist({
      type: 'setCity',
      payload: null
    });
  }, []);

  const dinamicCountrySelect = {
    options:
      lens.posType === 'T2'
        ? pointsOfSale.map(item => ({
          name: item.location.address,
          value: item.location.country.id,
          city: item.location.city.id,
          posId: item.id
        }))
        : countriesList.map(country => ({
          name: language ? country[`name_${language}`] : country.name,
          id_country: country.id,
          value: country.id
        }))
  };

  const handleGetStates = async countryId => {
    await PointsOfSaleService.state(countryId)
      .then(data => {
        setStatesList(data);
      })
      .catch(err => console.log(err));
  };

  const dinamicStateSelect = {
    options: statesList.map(state => ({
      name: language ? state[`name_${language}`] : state.name,
      id_state: state.id,
      value: state.id
    }))
  };

  const handleGetCities = async cityId => {
    await PointsOfSaleService.city(cityId)
      .then(data => {
        setCitiesList(data);
      })
      .catch(err => console.log(err));
  };

  const dinamicCitySelect = {
    options: citiesList.map(city => ({
      name: language ? city[`name_${language}`] : city.name,
      id_city: city.id,
      value: city.id
    }))
  };

  const setCity = (aCity, aCityStr) => {
    setError(null);
    dispatchPersist({
      type: 'setCity',
      payload: aCity
    });
    dispatchPersist({
      type: 'setCityStr',
      payload: aCityStr
    });
  };

  const setState = (aState, aStateStr) => {
    setError(null);
    dispatchPersist({
      type: 'setState',
      payload: aState
    });
    dispatchPersist({
      type: 'setStateStr',
      payload: aStateStr
    });
  };

  const setCountry = (aCountry, aCountryStr) => {
    setError(null);
    dispatchPersist({
      type: 'setCountry',
      payload: aCountry
    });
    dispatchPersist({
      type: 'setCountryStr',
      payload: aCountryStr
    });
  };

  const setPosId = posId => {
    setError(null);
    dispatchPersist({
      type: 'setPosId',
      payload: posId
    });
  };

  const handleClickIngresar = () => {
    setError(null);
    ReactGA.event({
      category: 'Product_Code_Activity',
      action: 'purchase_location_selected',
      label: `${countryStr}, ${stateStr}, ${cityStr}`
    });

    history.push({
      pathname: '/map'
    });
  };



  return (
    <HomeLensTemplate
      lens={lens}
      sku={lens && lens.sku}
      dinamicCountrySelect={dinamicCountrySelect}
      dinamicStateSelect={dinamicStateSelect}
      dinamicCitySelect={dinamicCitySelect}
      setPosId={setPosId}
      setCountry={setCountry}
      setState={setState}
      setCity={setCity}
      handleGetStates={handleGetStates}
      handleGetCities={handleGetCities}
      handleClickIngresar={handleClickIngresar}
      error={error}
    />
  );
};

export default withTranslation()(HomeLens);
