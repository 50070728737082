import React from 'react';
import ReactMapGl, { NavigationControl } from 'react-map-gl';
import CustomMarker from 'components/atoms/CustomMarker';
import 'mapbox-gl/dist/mapbox-gl.css';
import Environment from '../../../environment';
import './styles.scss';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapboxToken = Environment.mapbox_api_key;

const colorMap = {
  version: 8,
  name: 'Mapbox Streets tileset v8',
  sources: {
    'country-boundaries': {
      type: 'vector',
      url: 'mapbox://mapbox.country-boundaries-v1'
    }
  },
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': 'transparent'
      },
      interactive: false
    },
    {
      id: 'undisputed country boundary fill',
      source: 'country-boundaries',
      'source-layer': 'country_boundaries',
      type: 'fill',
      paint: {
        'fill-color': 'transparent',
        'fill-outline-color': '#0a7f9b'
      }
    },
    {
      id: 'disputed area boundary fill',
      source: 'country-boundaries',
      'source-layer': 'country_boundaries',
      type: 'fill',
      paint: {
        'fill-color': 'transparent',
        'fill-outline-color': '#0a7f9b'
      }
    }
  ]
};

const MapComponent = props => {
  const isActive = index => parseInt(props.routeIndex) === index;

  const memoizedColorMap = React.useMemo(() => {
    return {
      ...colorMap,
    };
  }, []);

  const memoizedMapboxToken = React.useMemo(() => {
    return mapboxToken;
  }, []);

  const memoizedMarkers = React.useMemo(() => {
    if (!props.markers) {
      return [];
    }

    return props.markers.flatMap((markersArrays, i) =>
      markersArrays.flatMap((marker, j) => {
        const points = marker.origin?.map((point, k) => (
          <CustomMarker
            latitude={point.latitude}
            longitude={point.longitude}
            isActive={isActive(i + 1)}
            key={`origin-${i}-${j}-${k}`}
            cardIndex={i + 1}
          />
        ));

        if (marker.destiny[0].type !== 'DistributionBatch') {
          const destinyPoints = marker.destiny.map((point, k) => (
            <CustomMarker
              latitude={point.latitude}
              longitude={point.longitude}
              isActive={isActive(i + 2)}
              key={`destiny-${i}-${j}-${k}`}
              cardIndex={i + 2}
            />
          ));

          const listDestiniesPoints = destinyPoints.length > 1 ? [destinyPoints.slice(1)] : (i === props.markers.length - 1 ? [destinyPoints] : []);

          return [...points, ...listDestiniesPoints];
        }

        return points;
      })
    );
  }, [props.markers, isActive]);

  return (
    <ReactMapGl
      {...props.viewport}
      onViewportChange={props.onViewportChange}
      dragPan={true}
      scrollZoom={false}
      mapboxApiAccessToken={memoizedMapboxToken}
      mapStyle={memoizedColorMap}
      attributionControl={false}
      style={{ background: 'linear-gradient(#0a273a, #000000 90%)' }}
    >
      {memoizedMarkers}
      {props.children}
      <div id="navigationControls">
        <NavigationControl showCompass={false} />
      </div>
    </ReactMapGl>
  );
};

export default React.memo(MapComponent)
