import React from 'react';
import { Marker } from 'react-map-gl';
import LocationPin from '../LocationPin';
import { isMobile } from 'helpers/Mobile';

const CustomMarker = ({ latitude, longitude, isActive, key, cardIndex }) => (
    <Marker
        latitude={latitude}
        longitude={longitude}
        key={key}
        style={{
            zIndex: isActive ? 2 : 1,
            transition: 'z-index 2s ease-out',
        }}
    >
        <LocationPin
            styles={{
                background: isActive && '#70E6F0',
                borderColor: isActive && '#70E6F0',
                transform: isMobile && isActive && 'scale(1.25)',
            }}
            cardIndex={cardIndex}
            numberStyles={{
                color: isActive ? '#000' : '#FFF',
            }}
        />
    </Marker>
);

export default React.memo(CustomMarker)
