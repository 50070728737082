import React from 'react';
import './styles.scss';

import Check from 'assets/images/aws-check.png';

const AwsCheck = () => {
  return (
    <span className="aws-check">
      <img src={Check} alt="Aws Blockchain" notranslate="true" /> AWS Blockchain{' '}
    </span>
  );
};

export default AwsCheck;
