import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Blockchain from '../../../assets/images/blockchain.png';
import AwsCheck from '../../atoms/AwsCheck';
import AuditLogModal from '../AuditLogModal';
import './styles.scss';
import '../LensDetail/styles.scss';

const CardLensDetailDesktop = React.memo(({ lens, blockchain }) => {
  const { t, i18n } = useTranslation();
  const [showAudit, setShowAudit] = useState(false);
  const handleShowAudit = () => setShowAudit(true);
  const handleCloseAudit = () => setShowAudit(false);

  // Default language EN
  const language = i18n.language !== 'en' && i18n.language;

  return useMemo(
    () => (
      <Card className="card-lens-master-desktop">
        <div className="card-lens-image-desktop">
          <div className="lens-image-container-desktop">
            {lens && lens.url && <img src={lens.url} alt="lens" />}
          </div>
        </div>

        <div className="card-content">
          <div id="card-info">
            <div className="card-lens-container-desktop">
              <div className="card-lens-desktop">
                <h3>
                  {language
                    ? lens?.[`name_trace_${language}`]
                    : lens.name_trace}
                </h3>
              </div>
            </div>

            <div className="card-lens-container-trace">
              <div className="card-lens-trace">
                <ul>
                  <li>
                    <b className="materiales-cardzero">
                      {t('mapZeroCard.materialBotin')}:{' '}
                    </b>{' '}
                    {(language
                      ? lens?.[`boot_material_${language}`]
                      : lens?.boot_material || ''
                    )?.length > 140
                      ? (language
                        ? lens?.[`boot_material_${language}`]
                        : lens?.boot_material || ''
                      ).slice(0, 140) + '...'
                      : language
                        ? lens?.[`boot_material_${language}`]
                        : lens?.boot_material || ''}
                  </li>
                  <li>
                    <b className="materiales-cardzero">
                      {t('mapZeroCard.materialBase')}:
                    </b>{' '}
                    {(language
                      ? lens?.[`base_material_${language}`]
                      : lens?.base_material || ''
                    )?.length > 140
                      ? (language
                        ? lens?.[`base_material_${language}`]
                        : lens?.base_material || ''
                      ).slice(0, 140) + '...'
                      : language
                        ? lens?.[`base_material_${language}`]
                        : lens?.base_material || ''}
                  </li>
                  <li>
                    <b className="materiales-cardzero">
                      {t('mapZeroCard.glassFiber')}:{' '}
                    </b>{' '}
                    {(language
                      ? lens?.[`recycled_materials_${language}`]
                      : lens?.recycled_materials || ''
                    )?.length > 140
                      ? (language
                        ? lens?.[`recycled_materials_${language}`]
                        : lens?.recycled_materials || ''
                      ).slice(0, 140) + '...'
                      : language
                        ? lens?.[`recycled_materials_${language}`]
                        : lens?.recycled_materials || ''}

                  </li>
                </ul>
                <div id="blockchain-data">
                  <p>
                    {t('mapZeroCard.blockchainTraceability')}
                    <img src={Blockchain} alt="Blockchain" />
                  </p>

                  <div className="detail-awscheck">
                    {/* <AwsCheck /> */}
                    <span
                      className="detail-audit-link"
                      onClick={handleShowAudit}
                    >
                      Audit Log
                    </span>
                  </div>
                  <AuditLogModal
                    showAudit={showAudit}
                    handleCloseAudit={handleCloseAudit}
                    lens={lens}
                    blockchain={blockchain}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    ),
    [lens, showAudit, blockchain, language]
  );
});

export default CardLensDetailDesktop;
