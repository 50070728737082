const Environment = {
    production: (process.env.REACT_APP_PRODUCTION === 'true'),
    api: process.env.REACT_APP_API,
    mapbox_api_key: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    analytics: process.env.REACT_APP_ANALYTICS,
    environment: process.env.REACT_APP_ENVIRONMENT
};

export default Environment;


