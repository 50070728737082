import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rootWidth } from 'helpers/Mobile';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-elastic-carousel';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import OpenImg from '../../../assets/images/open-img.svg';
import './styles.scss';

const CardWithImage = React.memo(({
  img,
  videos,
  title,
  index,
  onClick,
  handleShow,
  card,
  iconic,
  style
}) => {
  const { t } = useTranslation();
  const imgLength = React.useMemo(() => (img && img.length > 0 ? img.length : 0), [img]);

  const [show, setShow] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(img && imgLength > 0 && img[0]);
  const [showPhoto, setShowPhoto] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const carouselRef = useRef(null);
  const modalCarouselRef = useRef(null);


  const handleRestart = pageIndex => {
    if (imgLength > 1) {
      const totalItems = imgLength - 1;
      if (pageIndex === totalItems) {
        carouselRef.current.goTo(0);
      }
    }
  };

  const handlePhotoClick = photo => {
    setSelectedPhoto(photo);
    setShow(false);
    setShowPhoto(true);
  };

  const handleClosePhoto = () => {
    setShowPhoto(false);
    setShow(true);
  };
  return (
    <>
      <div className="card-with-image-container">
        <div className="card-iconic-image">
          <div className="iconic-image-container">
            {iconic && <img src={iconic} alt="iconic image" />}
          </div>
        </div>
        <Card className="card-with-image" onClick={onClick} style={style}>
          <Card.Body>
            <div className="card-title-container">
              <span>{index}</span>
              <h2>{title}</h2>
            </div>
            <div className="carousel-container" onClick={handleShowModal}>
              <Carousel
                ref={carouselRef}
                itemsToShow={1}
                initialActiveIndex={0}
                showArrows={false}
                pagination={false}
                enableAutoPlay={true}
                autoPlaySpeed={8000 * index}
                transitionMs={2500}
                enableTilt={true}
                onNextEnd={handleRestart}
              >
                {videos && videos.length > 0 &&
                  videos.map(
                    vd =>
                      vd.video !== null && (
                        <>
                          {vd.is_streaming_video ? (
                            <iframe
                              width="100%"
                              height="100%"
                              src={
                                vd.video +
                                '?enablejsapi=1&autoplay=1&loop=1&mute=1&controls=0'
                              }
                              title="Video"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              className='card-iframe-video'
                            ></iframe>
                          ) : (
                            <div className="video-container">
                              <video autoPlay playsInline loop muted>
                                <source src={vd.video} />
                              </video>
                            </div>
                          )}
                        </>
                      )
                  )}
                {img && imgLength > 0 &&
                  img.map((img, index) => (
                    <div
                      className="card-carousel-image-container"
                      id={`imagen-container-${index}`}
                    >
                      <img
                        className="icon-open-img"
                        src={OpenImg}
                        id={`icon-open-${index}`}
                        loading="lazy"
                      />
                      <img
                        src={
                          img.image
                        }
                        alt={title}
                        className={'card-carousel-image'}
                        loading="lazy"
                      />
                    </div>
                  ))}
              </Carousel>
            </div>
            <span
              id={`card-with-image-line-${index}`}
              className="card-with-image-line"
              onClick={() => handleShow(card)}
            >
              {t('mapAllCards.SeeMore')}
            </span>
          </Card.Body>
        </Card>
      </div>

      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal-carousel-container">
          <CloseButton variant="white" onClick={() => setShow(false)} />
          <Carousel
            verticalMode
            pagination={false}
            ref={modalCarouselRef}
            itemsToShow={rootWidth <= 360 ? 2 : 3}
            itemsToScroll={2}
            initialActiveIndex={0}
            itemPadding={[15, 0]}
          >
            {videos && videos.length > 0 &&
              videos.map(
                vd =>
                  vd.video !== null && (
                    <>
                      {vd.is_streaming_video ? (
                        <iframe
                          width="250"
                          height="124"
                          src={vd.video}
                          title="Video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className='card-iframe-video'
                        ></iframe>
                      ) : (
                        <div className="video-container">
                          <video controls>
                            <source src={vd.video} />
                          </video>
                        </div>
                      )}
                    </>
                  )
              )}

            {img && imgLength > 0 &&
              img.map(image => (
                <img
                  key={image.id + 'm-img'}
                  src={image.image}
                  alt={title}
                  className={'modal-card-carousel-image'}
                  onClick={() => handlePhotoClick(image)}
                  loading="lazy"
                />
              ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPhoto}
        onHide={handleClosePhoto}
        centered
        className="photo-modal-container"
      >
        <Modal.Body>
          <CloseButton variant="white" onClick={handleClosePhoto} />
          <img
            key={selectedPhoto?.id + 'm-photo'}
            src={selectedPhoto?.image}
            alt={title}
            className="photo-modal-img"
            loading="lazy"
          />
        </Modal.Body>
      </Modal>
    </>
  );
},
  (prevProps, nextProps) => {
    return (
      prevProps.img === nextProps.img &&
      prevProps.videos === nextProps.videos
    );
  }
);

export default CardWithImage;
