import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { ProductService } from 'services/ProductService';
import HomeTemplate from '../../components/templates/Home';
import ReactGA from 'react-ga4';
import Environment from 'environment';
import { useStorePersist } from 'store/context';
import { useTranslation } from 'react-i18next';
import { generateProductFields } from '../../helpers/ProductFields';

ReactGA.initialize(Environment.analytics);


const HomeView = props => {
  const { dealWithError } = useRequest();
  const [code, setCode] = useState();
  const [error, setError] = useState('');
  const [{ }, dispatchPersist] = useStorePersist();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem('routesData-en');
    localStorage.removeItem('routesData-es');
    localStorage.removeItem('routes-en');
    localStorage.removeItem('routes-es');
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('qr');
    if (queryParam) {
      setCode(queryParam);
    }
  }, [location]);

  const handleSearch = e => {
    setError('');
    if (!code || code.trim() == '') {
      setError([t('home.error')]);
      return;
    }
    if ((e.key === 'Enter' && code) || (e.type === 'click' && code)) {
      ReactGA.event({
        category: 'Product_Code_Activity',
        action: 'Code_Searched',
        label: code
      });

      let codeSplitted = code.split('-');
      if (codeSplitted.length !== 2) {
        ProductService.search(0, 0, code)
          .then(data => {
            if (data.detail === 'No encontrado.') {
              setError([t('home.error')]);
              return;
            }

            let lens = {
              ...generateProductFields(data),
              batch: data.manufacturing_batch,
              sku: data.sku
            };

            dispatchPersist({
              type: 'setLens',
              payload: lens
            });

            if (data.show_points_of_sale_screen) {
              history.push({
                pathname: '/search'
              });
            } else {
              history.push({
                pathname: '/map'
              });
            }
          })
          .catch(error => {
            setError([t('home.error')]);
            dealWithError(error, 'Error from QrScanner');
          });

        return;
      }

      ProductService.search(codeSplitted[0].trim(), codeSplitted[1].trim())
        .then(data => {
          let lens = {
            ...generateProductFields(data),
            batch: codeSplitted[1].trim(),
            sku: codeSplitted[0].trim()
          };
          dispatchPersist({
            type: 'setLens',
            payload: lens
          });

          if (data.show_points_of_sale_screen) {
            history.push({
              pathname: '/search'
            });
          } else {
            history.push({
              pathname: '/map'
            });
          }
        })
        .catch(error => {
          setError([t('home.error')]);
          dealWithError(error, 'Error from handleSearch @ HomeView');
        });
    }
  };

  const handleClick = e => {
    e.preventDefault();
  };

  return (
    <HomeTemplate
      code={code}
      setCode={setCode}
      handleSearch={handleSearch}
      handleClick={handleClick}
      error={error}
      setError={setError}
    />
  );
};

export default withTranslation()(HomeView);
