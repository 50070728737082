import Environment from "../environment";
import Api from './ApiService';

export const PointsOfSaleService = {
    list: (batch) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await Api.fetchNoToken(`${Environment.api}points-of-sale?id_code=${await batch}`, 'GET');
                resolve(data);
            } catch (err) {
                reject(err);
            }
        })
    },
    country: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await Api.fetchNoToken(`${Environment.api}deliverycountry`, 'GET');
                resolve(data);
            } catch (err) {
                reject(err);
            }
        })
    },
    state: (idCountry) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await Api.fetchNoToken(`${Environment.api}deliverystate?p=${idCountry}`, 'GET');
                resolve(data);
            } catch (err) {
                reject(err);
            }
        })
    },
    city: (idState) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await Api.fetchNoToken(`${Environment.api}deliverycity?p=${idState}`, 'GET');
                resolve(data);
            } catch (err) {
                reject(err);
            }
        })
    }
}
