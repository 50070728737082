import React, { useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { isMobile } from 'helpers/Mobile';
import { clearLocalStorage } from 'helpers/LocalStorageActions';
import { useStorePersist } from 'store/context';
import QrScanner from 'components/molecules/QrScanner';
import karunFooterLogo from 'assets/images/logo-kts.png';
import MainLayout from 'components/organisms/MainLayout';
import Form from 'react-bootstrap/Form';
import ActionButton from 'components/atoms/ActionButton';
import QrIcon from 'assets/images/qr-icon.png';
import step1 from 'assets/images/JTP_Step1.png';
import step2 from 'assets/images/JTP_Step2.png';
import step3 from 'assets/images/JTP_Step3.png';
import { ReactComponent as JoinPlanetDkt } from 'assets/images/join_planet_dkt.svg';
import { ReactComponent as JoinPlanetMobile } from 'assets/images/join_planet_mobile.svg';

import video from '../../../assets/videos/home_videobg_dkt.mp4';
import './styles.scss';
import QrExplanation from 'components/atoms/QrExplanation';

const Home = React.memo(({ code, setCode, handleSearch, error, setError }) => {
  //Clearing local storage cache
  const [{ locale }] = useStorePersist();
  clearLocalStorage(locale);
  const [openInstructions, setOpenInstructions] = useState(false);
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [startScan, setStartScan] = useState(false);
  const { t } = useTranslation();
  const stepsRef = useRef(null);

  const handleCode = e => {
    e.preventDefault();
    let code = e.target.value;
    // code = code.toUpperCase();
    setCode(code);
  };

  const handleStartScan = () => {
    setIsQrOpen(!isQrOpen);
    setStartScan(!startScan);
  };

  return (
    <MainLayout showMenu={false} isHome={true}>
      <div
        className="home-container"
        style={{ zIndex: openInstructions ? 1001 : 999 }}
      >
        {!isMobile && (
          <div className="video-background-container-dkt">
            <video autoPlay loop muted playsInline>
              <source src={video} />
            </video>
          </div>
        )}

        <div className="home-search">
          {isMobile ? (
            <JoinPlanetMobile className="join-planet-mobile" />
          ) : (
            <JoinPlanetDkt className="join-planet-dkt" />
          )}
          <p className="home-subtitle">{t('home.subtitle')}</p>
          <h1 className="home-title">{t('home.title')}</h1>
          <div
            className={
              'input-text-container input-search ' +
              (error !== '' ? 'error' : '')
            }
          >
            <Form.Group className="form-container">
              <Form.Label>{t('home.code')}</Form.Label>
              <div className="input-and-qr-container">
                <div className="input-qr-inner-container">
                  <div className="input-button-container">
                    <Form.Control
                      placeholder={t('home.placeholder')}
                      title={t('home.searchbartip')}
                      value={code || ''}
                      type={'text'}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          handleSearch(event);
                        }
                      }}
                      onChange={event => {
                        handleCode(event);
                      }}
                    />
                    <button
                      className={
                        'button-text-search' + (error != '' ? ' error' : '')
                      }
                      onClick={event => {
                        handleSearch(event);
                      }}
                    ></button>
                  </div>
                  <div className='qr-button-container'>
                    <ActionButton legend={QrIcon} handleClick={handleStartScan} />
                    <QrExplanation />
                  </div>
                </div>

                <QrScanner
                  setIsQrOpen={setIsQrOpen}
                  startScan={startScan}
                  setStartScan={setStartScan}
                  setError={setError}
                  error={error}
                />
              </div>
            </Form.Group>
          </div>
          <div
            className={
              'input-error input-search' + (error !== '' ? ' error' : '')
            }
          >
            {error &&
              error.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
          </div>

          <div className={'home-go-down'}>
            {/* Disabled until we have Karun's indications */}
            {/*  <p className="no-code-look">
              {t('home.look')}
              <a onClick={() => setOpen(true)} className="no-code-look">
                {t('home.here')}
              </a>
            </p> */}
            <p
              className={`how-to-enter-code ${isMobile && isQrOpen ? 'hide' : ''
                } `}
            >
              <a onClick={() => setOpenInstructions(true)}>{t('home.how')}</a>
            </p>
          </div>
          <div id="footer" className="karun-footer">
            <img
              className="footer-logo"
              src={karunFooterLogo}
              alt="Karun"
              width="200px"
            />
          </div>
        </div>
        {openInstructions && (
          <div className="overlay-background">
            <div className="home-instructions">
              <div className="home-steps" ref={stepsRef}>
                <button
                  className="home-instructions-closeButton"
                  onClick={() => setOpenInstructions(false)}
                >
                  X
                </button>
                <div className="home-instruction-step">
                  <h3>{t('home.labelstep1')}</h3>
                  <img src={step1} alt="step1" width="270px" />
                  <p>{t('home.step1')}</p>
                </div>
                <div className="home-instruction-step">
                  <h3>{t('home.labelstep2')}</h3>
                  <img src={step2} alt="step2" width="270px" />
                  <p>{t('home.step2')}</p>
                </div>
                <div className="home-instruction-step">
                  <h3>{t('home.labelstep3')}</h3>
                  <img src={step3} alt="step3" width="270px" />
                  <p>{t('home.step3')}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
});

export default withTranslation()(Home);
