export const reducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const reducerPersist = (state, action) => {
  switch (action.type) {
    case 'setLens':
      return { ...state, lens: action.payload };
    case 'setCity':
      return { ...state, city: action.payload };
    case 'setCityStr':
      return { ...state, cityStr: action.payload };
    case 'setState':
      return { ...state, state: action.payload };
    case 'setStateStr':
      return { ...state, stateStr: action.payload };
    case 'setCountry':
      return { ...state, country: action.payload };
    case 'setCountryStr':
      return { ...state, countryStr: action.payload };
    case 'setLocale':
      return { ...state, locale: action.payload };
    case 'setPosId':
      return { ...state, posId: action.payload };
    case 'setCardRouteIndex':
      return { ...state, cardRouteIndex: action.payload };
    case 'setTooltipIndex':
      return { ...state, tooltipIndex: action.payload };
    case 'setTooltipCompleted':
      return { ...state, tooltipCompleted: action.payload };
    default:
      return state;
  }
};
