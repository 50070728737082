import React, { useState, useMemo } from 'react';
import { isMobile } from 'helpers/Mobile';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import defaultCardImage from './default-card-image.png';
import { useStorePersist } from 'store/context';
import { Tooltip } from 'components/atoms/TooltipDialog/Tooltip';
import './styles.scss';

const DetailBodyDesktop = React.memo(
  ({
    id,
    box1,
    box2,
    title,
    content,
    index,
    images,
    videos,
    currentMaterial,
    certifications,
    onClick
  }) => {
    const [showImageInModal, setShowImageInModal] = useState(false);
    const handleCloseModal = () => setShowImageInModal(false);
    const [imageToShow, setImageToShow] = useState('');
    const [videoToShow, setVideoToShow] = useState('');
    const [showCertification, setShowCertification] = useState(false);
    const { t } = useTranslation();
    const [
      { cardRouteIndex, tooltipCompleted, tooltipIndex }
    ] = useStorePersist();

    function extractCardRouteIndexFromId(str) {
      return str.slice(-1);
    }
    const cardRouteIndexFromId = extractCardRouteIndexFromId(id);
    const handleHowCertification = () => {
      setShowImageInModal(!showImageInModal);
      setVideoToShow(false);
      setImageToShow(false);
      setShowCertification(true);
    };

    const [videoItems, imagesItems] = useMemo(() => {
      const videoItems = videos.map((vd, key) => {
        if (vd.video !== null && vd.is_streaming_video) {
          // video de fuentes externas
          return (
            <iframe
              key={`iframe-${key}`}
              width="250"
              height="124"
              src={vd.video}
              title="Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="card-iframe-video"
            ></iframe>
          );
        } else {
          // video cargado en el sistema
          return (
            <div
              className="video-container"
              key={`video-container-${key}`}
            >
              <video
                autoPlay
                loop
                muted
                onClick={() => {
                  setShowImageInModal(!showImageInModal);
                  setImageToShow(null);
                  setVideoToShow(vd);
                  setShowCertification(false);
                }}
              >
                <source src={vd.video} />
              </video>
            </div>
          );
        }
      });

      const imagesItems = images.map((img, key) => (
        <img
          key={`image-${key}`}
          src={img.image}
          alt={title}
          className={'detail-image-desktop'}
          onClick={() => {
            setShowImageInModal(!showImageInModal);
            setImageToShow(img);
            setVideoToShow(null);
            setShowCertification(false);
          }}
          loading="lazy"
        />
      ));

      return [videoItems, imagesItems];
    }, [videos, images]);

    return useMemo(
      () => (
        <div
          id={id}
          onClick={onClick}
          className={`route-details ${cardRouteIndex === cardRouteIndexFromId ? 'non-zero-routes' : ''
            }`}
        >
          {isMobile && (
            <span className="card-pill card-pill-material">
              {currentMaterial}
            </span>
          )}
          {cardRouteIndex !== 0 && (
            <div className="card-route-title-container">
              <span
                id={`card-title-index-${cardRouteIndex}`}
                className="card-title-index"
                style={{
                  display:
                    cardRouteIndex === cardRouteIndexFromId ? 'flex' : 'none'
                }}
              >
                {index}
              </span>
              <h5
                id={`card-title-${cardRouteIndex}`}
                className="card-title"
                style={{
                  display:
                    cardRouteIndex === cardRouteIndexFromId ? 'flex' : 'none'
                }}
              >
                {title}
              </h5>
            </div>
          )}
          <div id="card-container-desktop">
            <>
              <Carousel
                itemsToShow={1}
                initialActiveIndex={0}
                showArrows={true}
                pagination={false}
              >
                {videos && videos.length > 0 && videoItems}

                {images && images.length > 0 ? imagesItems
                  : (
                    <div id="imageHolder">
                      <img
                        src={defaultCardImage}
                        alt="Default Card Image"
                        className="detail-image-desktop"
                        loading="lazy"
                      />
                    </div>
                  )}
              </Carousel>

              <Modal
                show={showImageInModal}
                onHide={handleCloseModal}
                className={`desktop-media-modal ${!showCertification &&
                  'justify-content-center'}`}
                centered
                size="xl"
              >
                <Modal.Header closeButton>
                  <h5>
                    {imageToShow && imageToShow.title}
                    {videoToShow && videoToShow.title}
                    {showCertification && certifications[0].name}
                  </h5>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    {imageToShow && (
                      <img
                        src={
                          imageToShow.image
                        }
                        alt={title}
                        className={'detail-image-desktop-expanded'}
                        loading="lazy"
                      />
                    )}
                    {videoToShow && (
                      <video
                        autoPlay
                        controls
                        className="detail-video-desktop-expanded"
                      >
                        <source src={videoToShow.video} />
                      </video>
                    )}
                    {showCertification && (
                      <object
                        data={`${certifications[0].files[0].file}`}
                        type="application/pdf"
                      >
                        <embed
                          src={`${certifications[0].files[0].file}`}
                          type="application/pdf"
                        />
                      </object>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </>

            <div className="route-body-detail body">
              <div className="route-body-content">{content}</div>
              <div className="route-body-certifications">
                {certifications &&
                  certifications.length > 0 &&
                  certifications[0].files.length > 0 && (
                    <div
                      className={`certificaciones ${!tooltipCompleted ? 'certificaciones-tooltip' : ''
                        }`}
                    >
                      {!tooltipCompleted && tooltipIndex === 3 && <Tooltip />}

                      <button
                        className="boton-certificados"
                        onClick={handleHowCertification}
                      >
                        {certifications[0].name}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      ),
      [
        id,
        box1,
        box2,
        title,
        content,
        index,
        images,
        videos,
        currentMaterial,
        certifications,
        onClick,
        showImageInModal,
        imageToShow,
        cardRouteIndex,
        cardRouteIndexFromId,
        videoItems,
        imagesItems
      ]
    );
  }
);

export default DetailBodyDesktop;
