import Environment from '../environment';

import Api from './ApiService';

export const RouteService = {
  routes: (batch, material, country, city, language, posId, sku) => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(
        `${Environment.api}routes?id_code=${batch}${material ? `&material=${material}` : ''
        }${country ? `&country=${country}` : ''}${city ? `&city=${city}` : ''
        }&language=${language}${sku ? `&sku=${sku}` : ''}${posId ? `&pos_id=${posId}` : ''}`,
        'GET'
      )
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  cards: material => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(
        `${Environment.api}card-routes/?material=${material}`,
        'GET'
      )
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  materials: batch => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(
        `${Environment.api}raw_materials/batch/${batch}/`,
        'GET'
      )
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  blockchain: batch => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(
        `${Environment.api}blockchain/batch/${batch}/`,
        'GET'
      )
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
