import React, { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers/Mobile';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Environment from 'environment';
import useRequest from 'hooks/useRequest';
import { ProductService } from 'services/ProductService';
import { useStorePersist } from 'store/context';
import { generateProductFields } from 'helpers/ProductFields';
import './styles.scss';
import Spinner from 'react-bootstrap/Spinner';

const QrReader = lazy(() =>
  import('react-qr-reader').then(module => ({ default: module.QrReader }))
);

const QrScanner = ({
  setIsQrOpen,
  startScan,
  setStartScan,
  setError,
  error
}) => {
  const { dealWithError } = useRequest();
  const [{ }, dispatchPersist] = useStorePersist();
  const { t } = useTranslation();
  const history = useHistory();

  const handleScan = async scanData => {
    setError('');

    if (scanData && scanData !== '') {
      ProductService.search(0, 0, scanData.text)
        .then(data => {
          ReactGA.event({
            category: 'Product_Code_Activity',
            action: 'Code_Searched',
            label: scanData.text
          });


          if (data.detail === 'No encontrado.') {
            setError([t('home.error')]);
            dealWithError(error, 'QR Service error');
            setIsQrOpen(false);
            setStartScan(false);
            return;
          }

          let lens = {
            ...generateProductFields(data),
            batch: data.manufacturing_batch,
            sku: data.sku
          };

          dispatchPersist({
            type: 'setLens',
            payload: lens
          });

          if (data.show_points_of_sale_screen) {
            history.push({
              pathname: '/search'
            });
          } else {
            history.push({
              pathname: '/map'
            });
          }
        })
        .catch(error => {
          setError([t('home.error')]);
          dealWithError(error, 'Error from QrScanner');
        });

      setIsQrOpen(false);
      setStartScan(false);
    }
  };

  return (
    <>
      {startScan && (
        <>
          <Suspense
            fallback={
              <Spinner
                animation="grow"
                variant="light"
                className="mt-4"
                role="status"
              />
            }
          >
            <QrReader
              constraints={{
                facingMode: isMobile ? 'environment' : 'user'
              }}
              onResult={handleScan}
              videoContainerStyle={{
                width: window.innerWidth <= 360 ? '200px' : '300px',
                paddingTop: '225px',
                marginTop: isMobile ? '0' : '1em'
              }}
              className="QrReader"
            />
          </Suspense>
        </>
      )}
    </>
  );
};

export default QrScanner;
