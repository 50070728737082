import React, { useEffect, useState, useRef, useMemo } from 'react';
import MapComponent from '../../molecules/Map';
import { FlyToInterpolator, Layer, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactGA from 'react-ga4';
import Environment from 'environment';
import './styles.scss';
import CardWithImage from '../../molecules/CardWithImage';
import Detail from '../../molecules/Detail';
import * as turf from '@turf/turf';
import Carousel from 'react-elastic-carousel';
import MainLayout from 'components/organisms/MainLayout';
import CardTitle from '../../atoms/CardTitle';
import { isLaptopL, isMobile, isNotebook } from '../../../helpers/Mobile';
import DetailBodyDesktop from '../../molecules/DetailBodyDesktop';
import CardLensDetail from 'components/molecules/CardLensDetail';
import LastCardMobile from 'components/molecules/LastCardMobile';
import CardLensDetailDesktop from 'components/molecules/CardLensDetailDesktop';
import Co2Status from 'components/molecules/Co2Status';
import { useTranslation } from 'react-i18next';
import { useStorePersist } from 'store/context';
import { TooltipDialog } from 'components/atoms/TooltipDialog/TooltipDialog';
import TooltipFade from 'components/atoms/TooltipDialog/TooltipFade';
import { Tooltip } from 'components/atoms/TooltipDialog/Tooltip';
import TooltipSwitch from 'components/atoms/TooltipDialog/TooltipSwitch';
import TooltipTopBar from 'components/atoms/TooltipDialog/TooltipTopBar';
import Modal from 'react-bootstrap/Modal';

ReactGA.event({
  category: 'Product_Code_Activity',
  action: 'entered_map_screen',
});

const MapTemplate = ({
  initialViewport,
  markers,
  cardsData,
  currentMaterial,
  handleClose,
  lens,
  country,
  city,
  blockchain,
  deliveryCarbonFootprint
}) => {
  const [viewport, setViewport] = useState(initialViewport);
  const [routeIndex, setRouteIndex] = useState(0);
  const [routes, setRoutes] = useState([]);
  const [detail, setDetail] = useState({});
  const [cardZero, setCardZero] = useState(true);
  const [fromTimeLineArrow, setFromTimelineArrow] = useState(0);
  const [currentTimelineIndex, setCurrentTimelineIndex] = useState(0);
  const { i18n, t } = useTranslation();
  const language = i18n.language;
  const cardsCarouselRef = useRef(null);
  const [
    { cardRouteIndex, tooltipCompleted, tooltipIndex },
    dispatchPersist
  ] = useStorePersist();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    cardsCarouselRef.current?.goTo(Number(!tooltipCompleted ? 1 : 0));
  }, [tooltipCompleted]);

  useMemo(() => {
    //Within this useEffect, each movement of the map is calculated and executed by setting the Viewport that will be consumed by the MapComponent.
    if (markers?.length) {
      // viewportmarkers = Defines a copy of markers, which is the array of multipleRoutes. (L-67 to L-102)
      // This array will be used to set the viewport to be consumed by the MapComponent according to the index modified by the step controls of the map, both on mobile and desktop. "

      const viewportMarkers = markers.map(arrayMarkers => {
        if (arrayMarkers.length > 1) {
          let originsCoords = arrayMarkers.map(point => [
            point.origin[0].longitude,
            point.origin[0].latitude
          ]);
          let originsPoints = turf.points(originsCoords);
          let centerCoord = turf.center(originsPoints);

          let distancesToCenter = originsPoints.features.map(point =>
            turf.distance(point, centerCoord)
          );
          let farestDistance = Math.max(...distancesToCenter);

          let middlePoint = {
            type: arrayMarkers[0].origin[0].type,
            latitude: centerCoord.geometry.coordinates[1],
            longitude: centerCoord.geometry.coordinates[0],
            farestDistance: farestDistance
          };

          return middlePoint;
        } else {
          return arrayMarkers[0].origin[0];
        }
      });

      viewportMarkers.unshift({
        type: 'ZeroCard',
        latitude: isMobile ? 0 : isLaptopL ? -25 : isNotebook ? -5 : 5,
        longitude: 40
      });
      viewportMarkers.push(
        markers[markers.length - 1][markers[markers.length - 1].length - 1]
          .destiny[0]
      );

      // According to the condition in the switch's viewport, it modifies the zoom and latitude in the viewportMarkers if necessary.(L-106 to L-129 )
      let dynamicZoom;

      switch (true) {
        case viewportMarkers[routeIndex].type === 'ZeroCard':
          dynamicZoom = isMobile ? 0 : isNotebook ? 1 : 2;
          break;
        case viewportMarkers[routeIndex].farestDistance &&
          viewportMarkers[routeIndex].farestDistance > 2000:
          dynamicZoom = isMobile ? 0 : isNotebook ? 1 : 2;
          viewportMarkers[routeIndex].latitude = isMobile
            ? 0
            : isLaptopL
              ? -25
              : isNotebook
                ? -5
                : 5;
          break;
        case viewportMarkers[routeIndex].farestDistance:
          dynamicZoom = 5;
          break;
        default:
          dynamicZoom = isMobile ? 3 : isNotebook ? 3 : 5;
          break;
      }

      //Creates a newViewport based on the active or selected index.
      let newViewport = {
        ...viewport,
        ...(viewportMarkers[routeIndex] ?? viewportMarkers[routeIndex + 1]),
        transitionDuration: isMobile ? 2500 : 5000,
        transitionInterpolator: new FlyToInterpolator(),
        zoom: dynamicZoom
      };

      // It is evaluated whether the shortest route is outside the Greenwich Meridian. (L-142 to L-170)
      // In such a case, intermediate viewport adjustments are generated between point and point to force it to pass through the center of the map and not outside.

      //orderedLng = Sort in descending order (from highest to lowest)
      const orderedLng = [viewport.longitude, newViewport.longitude].sort(
        (a, b) => b - a
      );

      //rangeByGreenwich = Distance between the two longitudinal points.
      const rangeByGreenwich = orderedLng[0] - orderedLng[1];

      // greenwichMidPoint = This results in the midpoint between the two longitudinal points.
      const greenwichMidPoint = orderedLng[1] + rangeByGreenwich / 2;

      if (rangeByGreenwich >= 180) {
        // If it is greater than or equal to 180, it means that the route going outside the map is shorter. Something we don't want.

        const zeroLngViewport = { ...newViewport };
        zeroLngViewport.zoom = isMobile ? 0 : 1;

        //The midpoint between the latitude of origin and destination is calculated, and for the longitude, the previously calculated Greenwich midpoint is used. (L-161 to L-167)

        let start = turf.point([viewport.longitude, viewport.latitude]);
        let end = turf.point([newViewport.longitude, newViewport.latitude]);
        let midPoint = turf.midpoint(start, end);

        zeroLngViewport.longitude = greenwichMidPoint;
        zeroLngViewport.latitude = midPoint.geometry.coordinates[1];
        zeroLngViewport.transitionDuration = isMobile ? 1500 : 3000;

        newViewport.transitionDuration = isMobile ? 1500 : 3000;

        // The midpoint between origin and destination is set as the intermediate viewport. Then, to smoothly display the movement, a setTimeout is generated to set the final destination of the viewport.(L-173 to L-181)
        setViewport(zeroLngViewport);

        setTimeout(
          () => {
            setViewport(newViewport);
          },
          isMobile ? 1500 : 3000
        );
      } else {
        setViewport(newViewport);
      }
    }
  }, [routeIndex, markers]);

  useMemo(() => {
    //In this useEffect, the route lines are set on the map. This is consumed by the Map component with its Layer component, which is in this file.
    if (markers?.length) {
      const routes = markers.flatMap((
        step //It's a copy of the markers that only returns the origin and destination points to draw the route lines on the map.
      ) =>
        step.flatMap(material => {
          return material.origin.map(fork => {
            //According to the distance or length of the line, its curvature is calculated as needed,
            // using the Turf library to perform the necessary calculations. (L-198 to L-228)
            const from = turf.point([fork.longitude, fork.latitude]);
            const to = turf.point([
              material.destiny[0].longitude,
              material.destiny[0].latitude
            ]);

            const start = [fork.longitude, fork.latitude];
            const middle = [
              fork.longitude + material.destiny[0].longitude / 2,
              fork.latitude + material.destiny[0].latitude / 2
            ];
            const end = [
              material.destiny[0].longitude,
              material.destiny[0].latitude
            ];
            const midpoint = turf.midpoint(from, to);
            const line = turf.lineString([
              start,
              midpoint.geometry.coordinates,
              end
            ]);
            const lineDistance = turf.length(line);
            const validatedLine = turf.lineString([
              start,
              lineDistance > 15000 ? middle : midpoint.geometry.coordinates,
              end
            ]);

            return turf.bezierSpline(validatedLine, {
              resolution: lineDistance > 10000 ? 10000 : 10
            });
          });
        })
      );

      setRoutes([routes]);
    }
  }, [markers]);

  const flyTo = index => {
    if (index > cardsData.length) {
      setRouteIndex(index - 1);
      ReactGA.event({
        category: 'Product_Code_Activity',
        action: 'trace_point_viewed',
        label: `${index.toString()} - ${index === 0 ? "Card Zero" : cardsData?.[index - 2].title}`
      });
    } else {
      setRouteIndex(index);
      ReactGA.event({
        category: 'Product_Code_Activity',
        action: 'trace_point_viewed',
        label: `${index.toString()} - ${index === 0 ? "Card Zero" : cardsData?.[index - 1].title}`
      });
    }

  };

  const handleShow = cardDetail => {
    setDetail(cardDetail);
  };

  const isElementOnScreen = id => {
    const element = document.getElementById(id);
    if (element) {
      const bounds = element.getBoundingClientRect();

      return bounds.top < window.innerHeight && bounds.bottom > 0;
    }
    return false;
  };

  const onRouteDetailsScroll = () => {
    if (markers.length) {
      for (let i = 0; i < markers.length; i++) {
        if (isElementOnScreen(`route-detail-${i}`)) {
          flyTo(i);
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (cardRouteIndex !== 0 && !cardZero) {
      onPointFocus(cardRouteIndex);
    } else {
      dispatchRouteIndex(0);
    }
  }, [language, cardRouteIndex]);

  useEffect(() => {
    if (cardRouteIndex === 0 && cardZero) {
      onPointFocus(0);
    }
  }, []);

  const dispatchRouteIndex = index => {
    dispatchPersist({
      type: 'setCardRouteIndex',
      payload: index
    });
  };

  // Function that handle the focus of the points on the timeline and what they show on the map
  const onPointFocus = i => {
    //Seteo el indice de OnPointFocus en el state
    dispatchRouteIndex(i);

    // Ocultar todos los elementos
    for (let j = 1; j <= markers.length + 1; j++) {
      let element = document.getElementById(`route-detail-${j}`);
      let button = document.getElementById(`route-${j}`);
      if (element) {
        element.style.display = 'none';
        button.classList.remove('active-route-button');
        button.classList.add('inactive-route-button');
      }
    }

    // Mostrar el elemento correspondiente al punto actual
    let focusedElement = document.getElementById(`route-detail-${i}`);
    let focusedButton = document.getElementById(`route-${i}`);
    if (focusedElement) {
      focusedElement.style.display = 'block';
      focusedButton.classList.remove('inactive-route-button');
      focusedButton.classList.add('active-route-button');
    }
    setFromTimelineArrow(i);
    setCurrentTimelineIndex(i);
  };

  function handleArrowTimelineBack() {
    if (fromTimeLineArrow === 0) {
      (() => {
        setCardZero(true);
        setFromTimelineArrow(0);
        onPointFocus(0);
        let focusedButton = document.getElementById(`route-0`);
        focusedButton.classList.remove('active-route-button');
      })();
    } else {
      setFromTimelineArrow(currentTimelineIndex - 1);
      onPointFocus(currentTimelineIndex - 1);
      if (currentTimelineIndex - 1 === 0) setCardZero(true);
    }
  }

  function handleArrowTimelineForward() {
    setFromTimelineArrow(currentTimelineIndex);
    if (currentTimelineIndex === cardsData.length) {
      handleShowPopup();
    } else {
      onPointFocus(currentTimelineIndex + 1);
      flyTo(currentTimelineIndex + 1);
    }
  }

  useEffect(() => {
    const co2Container = document.getElementById('co2-container');

    if (co2Container) {
      if (cardRouteIndex !== 0 && !cardZero) {
        onPointFocus(cardRouteIndex);
        co2Container.classList.add('co2-container-non-zero-card');
      } else {
        dispatchRouteIndex(0);
        if (co2Container.classList.contains('co2-container-non-zero-card')) {
          co2Container.classList.remove('co2-container-non-zero-card');
        }
      }
    }
  }, [cardRouteIndex]);

  const handleShowPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  return (
    <>
      {!tooltipCompleted && (
        <>
          <TooltipDialog />
          <TooltipTopBar />
        </>
      )}

      <MainLayout
        isTrace={true}
        handleClose={handleClose}
        className="map-template"
        lens={lens}
      >
        <div>
          {viewport && (
            <MapComponent
              viewport={viewport}
              markers={markers}
              onViewportChange={setViewport}
              routeIndex={routeIndex}
            >
              {routes.map((route, i) =>
                route.map((r, j) => (
                  <Source
                    id={`route-${i}${j}`}
                    type="geojson"
                    data={r}
                    key={`route-map-${i}${j}`}
                  >
                    <Layer
                      id={`route-layer-${i}${j}`}
                      key={`route-layer-${i}${j}`}
                      source={`route-${i}${j}`}
                      type="line"
                      paint={{
                        'line-opacity': 1,
                        'line-color': '#FFF',
                        'line-width': 2,
                        'line-dasharray': isMobile ? [2, 2] : [1, 1]
                      }}
                    />
                  </Source>
                ))
              )}
            </MapComponent>
          )}
          {isMobile ? (
            <>
              {!tooltipCompleted && tooltipIndex === 1 && <Tooltip />}
              {!tooltipCompleted && tooltipIndex === 2 && <Tooltip />}
              <div className="cards-container">
                {tooltipCompleted && <TooltipSwitch />}
                {!tooltipCompleted && <TooltipFade />}

                <Carousel
                  ref={cardsCarouselRef}
                  enableSwipe={false}
                  itemsToShow={1}
                  initialActiveIndex={!tooltipCompleted ? 1 : 0}
                  showArrows={true}
                  pagination={false}
                  renderItemInView="center"
                  onChange={currentItem => {
                    flyTo(JSON.stringify(currentItem.item.index));
                  }}
                >
                  <CardLensDetail
                    lens={lens}
                    city={city}
                    country={country}
                    handleClose={handleClose}
                    blockchain={blockchain}
                    key={0}
                    index={0}
                    deliveryCarbonFootprint={deliveryCarbonFootprint}
                  />

                  {cardsData?.length > 0 &&
                    cardsData.map((card, index) => (
                      <CardWithImage
                        title={card.title}
                        key={index}
                        index={index + 1}
                        content={card.short_description}
                        card={card}
                        img={card.images}
                        iconic={lens.url}
                        videos={card.videos}
                        handleShow={handleShow}
                      />
                    ))}
                  <LastCardMobile
                    key={cardsData.length + 1}
                    index={cardsData.length + 1}
                    iconic={lens.url}
                  />
                </Carousel>
              </div>
              <Detail
                show={detail.id}
                title={detail.title}
                content={
                  <>
                    <h2>{detail.sub_title}</h2>
                    <p>{detail.complete_description}</p>
                  </>
                }
                images={detail.images}
                videos={detail.videos}
                box1={detail.box1}
                box2={detail.box2}
                setShow={setDetail}
                certifications={detail.certifications}
                currentMaterial={currentMaterial}
                id={markers[0][0].origin[0].type}
              />
            </>
          ) : (
            /* Contenedor Card Zero / Regular step Cards*/
            <div
              className={'route-details-sidebar cardzero-noscroll'}
              onScroll={onRouteDetailsScroll}
            >
              {!tooltipCompleted ? <TooltipFade /> : <TooltipSwitch />}

              {!cardZero && (
                <img className="card-zero-overlap" src={lens.url} />
              )}
              <div id="timeline-container-bg"></div>
              {!tooltipCompleted && tooltipIndex === 2 && <Tooltip />}
              <div id="timeline-container">
                {/*//-- Boton Arrow Back  */}
                <button
                  id={'timeline-back'}
                  key={'button-back-arrow'}
                  className={
                    cardZero
                      ? 'timeline-arrow-disabled'
                      : 'timeline-arrow-cards'
                  }
                  onClick={() => {
                    flyTo(
                      currentTimelineIndex === 0 ? 0 : currentTimelineIndex - 1
                    );
                    handleArrowTimelineBack();
                  }}
                  disabled={cardZero ? true : false}
                ></button>

                <div id="timeline">
                  {/*//- Boton Card Zero  */}
                  <button
                    id={'route-0'}
                    key={'button-key-0'}
                    className={
                      cardZero ? 'active-route-button' : 'inactive-route-button'
                    }
                    onClick={() => {
                      setCardZero(true);
                      flyTo(0);
                      onPointFocus(0);
                    }}
                  >
                    0
                  </button>

                  {/*//- Botones timeline del 1 a N */}
                  {cardsData.map((card, index) => (
                    <button
                      id={`route-${index + 1}`}
                      key={`button-key-${index + 1}`}
                      className="inactive-route-button"
                      onClick={() => {
                        setCardZero(false);
                        flyTo(index + 1);
                        onPointFocus(index + 1);
                      }}
                    >
                      {index + 1}
                    </button>
                  ))}

                  <button
                    id={`route-${cardsData.length + 1}`}
                    key={`button-key-${cardsData.length + 1}`}
                    className="inactive-route-button"
                    onClick={handleShowPopup}
                  >
                    +
                  </button>
                </div>

                {/*//--! Boton Arrow Forward  */}
                <button
                  id={'timeline-forward'}
                  key={'button-forward-arrow'}
                  className={cardZero ? '' : 'timeline-arrow-cards'}
                  onClick={() => {
                    setCardZero(false);
                    handleArrowTimelineForward();
                  }}
                  disabled={currentTimelineIndex === cardsData.length + 1}
                ></button>
                {!tooltipCompleted && tooltipIndex === 1 && <Tooltip />}
              </div>

              {cardZero && (
                <CardLensDetailDesktop lens={lens} blockchain={blockchain} deliveryCarbonFootprint={deliveryCarbonFootprint} />
              )}

              {cardsData &&
                cardsData.map((card, index) => (
                  <DetailBodyDesktop
                    id={`route-detail-${index + 1}`}
                    key={card.id}
                    title={card.title}
                    content={
                      <>
                        <h2>{card.sub_title}</h2>
                        <p>{card.complete_description}</p>
                      </>
                    }
                    index={index + 1}
                    images={card.images}
                    videos={card.videos}
                    box1={card.box1}
                    box2={card.box2}
                    certifications={card.certifications}
                    currentMaterial={currentMaterial}
                    cardZero={cardZero}
                  />
                ))}
              <Co2Status lens={lens} deliveryCarbonFootprint={deliveryCarbonFootprint} />
            </div>
          )}

          <Modal
            show={showPopup}
            onHide={handleClosePopup}
            className="desktop-info-footprint-modal justify-content-center"
            centered
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <p>
                {t('mapLastCard.KarunSeeMore')}
                <a className='karun-seemore-link' href='https://www.karunworld.com/jointheplanet' target='_blank'>{t('mapLastCard.here')}</a>
              </p>

            </Modal.Body>
          </Modal>

        </div>
      </MainLayout>
    </>
  );
};

export default MapTemplate;
